.footer {
  position: relative;
  display: block;
  background: var(--color-gray-side);
  color: var(--color-blue);
  padding: 0;
  width: 100%;
  margin-top: auto;
}

.sc_con {
  position: relative;
  width: 100%;
  display: flex;
  margin: 0 auto;
  max-width: calc(2880px - var(--l-side-width) - var(--r-side-width));
}
@media (max-width: 991px) {
  .sc_con {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .footer .sc_con {
    display: flex;
    flex-wrap: wrap;
  }
}

.sc_col {
  position: relative;
  width: 100%;
  padding: 4.8rem var(--gap);
}
@media (max-width: 1600px) {
  .sc_col {
    padding: var(--gap);
  }
}
@media (max-width: 767px) {
  .sc_col {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .footer .sc_col {
    width: 100%;
  }
}

.footer_item {
  position: relative;
  line-height: 1.333333333;
}

.footer_item:not(:last-child) {
  margin-bottom: 3.2rem;
}

.footer_item h3 {
  text-transform: uppercase;
}
.footer_item h3:not(:last-child) {
  padding-bottom: 0;
}

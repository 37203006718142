@import url('https://fonts.googleapis.com/css?family=Inconsolata');

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
@media (max-width: 2300px) {
  html {
    font-size: 7.5px;
  }
}
@media (max-width: 1600px) {
  html {
    font-size: 6px;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 10px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--base-text-color);
  background: var(--base-background-color);
  font: var(--base-font-size) / var(--base-line-height) var(--base-font-family);
  min-width: var(--base-min-width);
  font-weight: 400;
  overflow-anchor: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button,
h5,
h3,
form {
  font-family: "Inconsolata SemiCondensed Bold", sans-serif;
  font-weight: bold;
  font-style: normal;
}

input {
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 3.4rem;
}
h1 {
  font-size: var(--headings-sizes-h1);
}
h2 {
  font-size: var(--headings-sizes-h2);
}
h3 {
  font-size: var(--headings-sizes-h3);
  line-height: 1.166666667;
  letter-spacing: 0.2em;
}
h4 {
  font-size: var(--headings-sizes-h4);
}
h5 {
  font-size: var(--headings-sizes-h5);
}
h6 {
  font-size: var(--headings-sizes-h6);
}

p {
  margin: 0;
  font-size: 2.4rem;
}

a {
  transition: color 0.3s ease-in-out;
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: var(--color-red);
}

img {
  max-width: 100%;
  height: auto;
}

.aside {
  width: 12rem;
  min-width: 12rem;
  --side-pd: 4.8rem 3.2rem;
  position: relative;
  background-color: white;
  
}

.side_content {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 12rem;
  height: 100%;
  padding: 4.8rem 3.2rem;
  z-index: 0;
  background-position: top left;
  background-size: 50rem auto;
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: inherit;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.side_block-socials {
  position: relative;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: inherit;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1;
}

.socials {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
}

.icon {
  width: 3.2rem !important;
  height: 3.2rem !important;
  color: var(--color-blue);
}

@media (max-width: 1199px) {
  .aside {
    display: none;
  }
}

.content {
  width: 100%;
  height: 100vh;
  color: var(--color-white);
  background: var(--gradient-main);
  padding: calc(var(--header-height) * 2) var(--content-padding);
}

.page_slogan_container {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 80%;
}

.page_slogan_col, .page_slogan_text {
  position: relative;
  width: 100%;
  display: flex;
  padding: 4.8rem var(--gap);
}

.page_slogan_image {
}

.page_slogan_image_words {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  z-index: 1;
  font-size: 80px;
  text-transform: uppercase;
  gap: 120px;
}

.page_slogan_image_words div:nth-child(1) {
  flex: 1;
  justify-content: center;
  text-align: right;
}

.page_slogan_image_words div:nth-child(2) {
  flex: 1;
  justify-content: center;
}

.page_slogan_text {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 50px;
  text-transform: uppercase;
}

.page_subtitle_container {
  display: flex;
  margin: -20vh auto 0 auto;
  width: 80%;
}

.page_subtitle_container-left {
  flex: 1;
}

.page_subtitle_container-right{
  flex: 1;
  text-transform: uppercase;
}

@media (max-width: 1400px) {
  .page_slogan_text {
    font-size: 42px;
  }

  .page_subtitle_container {
    margin-top: -10vh;
  }
}

@media (max-width: 1199px) {
  .page_slogan_container {
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
  }

  .page_subtitle_container {
    margin: auto;
  }

  .page_slogan_col, .page_slogan_text {
    margin: 0 auto;
  }
  
  .page_slogan_text {
    flex-direction: column;
    margin-top: -40%;
  }

  .chevron_right {
    transform: rotate(90deg);
  }

  .page_slogan_image {
  }
  
  .page_slogan_image_words {
  }

  .page_subtitle_container-left {
    flex: 0;
  }

  .page_subtitle_container-right {
    text-align: center !important;
  }
}

@media (max-width: 991px) {
  .content {
    padding: 0;
  }

  .page_slogan_container {
    width: 60%;
  }
  
  .page_slogan_text {
    flex-direction: column;
    margin-top: -50%;
  }

}

@media (max-width: 767px) {
  .page_slogan_container {
    width: 70%;
  }
}

@media (max-width: 599px) {
  .content {
    padding: 3vh 0 0 0;
  }

  .page_slogan_image_words {
    gap: 65px;
  }

  .page_slogan_image_words {
    font-size: 60px;
  }
  
  .page_slogan_text {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .page_slogan_image_words {
    gap: 45px;
  }

  .page_slogan_image_words {
    font-size: 50px;
  }
  
  .page_slogan_text {
    font-size: 30px;
  }
}

.form_container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disabled {
  pointer-events: none;
  opacity: .4;
}

.page_description_container {
  position: relative;
  display: flex;
  font-size: 30px;
}

.page_description_title, .page_description_form {
  padding: 40px 80px;
}

.page_description_title {
  flex: 3;
}

.page_description_title p {
  font-size: 20px;
  text-align: right;
}

.page_description_form {
  border-left: 1px solid var(--color-gray);
  flex: 4;
}

html .form {
  font-size: var(--form-font-size);
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}

.chevron_right {
  width: 50px !important;
  height: 50px !important;
  margin-right: 20px;
}

input {
  width: 100%;
  background: #f1f1f1;
  border: none;
  padding: 10px;
} 

.form_control {
  display: inline-block;
}

.form_control label{
  z-index: 1000;
  position: absolute;
}

.form_control input{
  z-index: 100;
}

.form_field {
  display: flex;
  width: 100%;
  padding: 0 3.2rem;
  border-bottom: 2px solid var(--color-gray);
}

.form_field label {
  text-transform: uppercase;
  padding: 1.5rem 0;
  margin-right: 2.4rem;
  letter-spacing: 0.2em;
  min-width: max-content;
  font-size: var(--base-font-size);
}

.form_field input {
  font-family: 'Inconsolata', sans-serif;
  background: rgba(0, 0, 0, 0);
  outline: none;
}

.form_field select {
  margin: 0 0 0 auto;
  border-radius: 0;
  color: inherit;
  outline: none;
  border: none;
  transition: border-color var(--base-transition) ease-in-out;
  padding: 1.5rem;
  font-size: var(--base-font-size);
}

.form-button {
  cursor: pointer;
  border: none;
  clip-path: polygon(100% 0, 100% 55%, 50% 100%, 0 55%, 0 0);
  background: var(--color-red);
  color: var(--color-white);
  transition: background-color 0.3s ease-in-out;
  font-size: var(--headings-sizes-h5);
  width: 24rem;
  padding: 2rem 2rem 6rem 2rem;
  height: 12rem;
}

.form-button_text {
  text-transform: uppercase;
}

.form_field--file {
  display: flex;
  margin: 20px 0;
  width: 100%;
}

.form_field--file-error label {
  border: 2px red solid !important;
}

.form_field--file input { 
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.form_field--file label { 
  text-transform: uppercase;
  padding: 1.5rem 0;
  letter-spacing: 0.2em;
  border: 2px solid var(--color-gray);
  width: 100%;
  text-align: center;
  color: var(--base-text-color);
  cursor: pointer;
  font-size: var(--base-font-size);
}

@keyframes rotateforever {
  to {
    transform: rotateZ(-360deg);
  }
}
@keyframes cubeRightSideBgChange {
  0% {
    background-color: var(--cube-2);
  }
  25% {
    background-color: var(--cube-1);
  }
  50% {
    background-color: var(--cube-3);
  }
}
@keyframes cubeLeftSideBgChange {
  0% {
    background-color: var(--cube-3);
  }
  25% {
    background-color: var(--cube-2);
  }
  50% {
    background-color: var(--cube-1);
  }
}
@keyframes cubeTopSideBgChange {
  0% {
    background-color: var(--cube-1);
  }
  25% {
    background-color: var(--cube-3);
  }
  50% {
    background-color: var(--cube-2);
  }
}
.cube {
  position: relative;
  margin: 20px auto;
  height: var(--cube-size);
  width: var(--cube-size);
  transform-style: preserve-3d;
}

.cube.animated .s {
  animation-duration: 1s !important;
}

.cube_wrap {
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 3;
  left: calc(100% / 3);
  top: calc(
    var(--vh, 1vh) * 50 - var(--cube-size) / 2 - (var(--cube-size) * 0.2)
  );
  width: var(--cube-size);
  height: var(--cube-size);
  margin-left: calc(-0.5 * var(--cube-size));
  transform-origin: center;
  transform: scale(0.5);
  transition: transform 0.3s ease-in-out;
}

.cube .s {
  position: absolute;
  height: calc(var(--cube-size) * 1);
  width: calc(var(--cube-size) * 1);
  transition: background-color 1s ease;
}
.cube .s-1 {
  background-color: var(--cube-2);
  transform: rotateX(-35deg) rotateY(45deg)
    translateZ(calc(var(--cube-size) / 2));
  animation: 3s cubeRightSideBgChange infinite ease-in-out forwards;
}
.cube .s-2 {
  transform: rotateX(-35deg) rotateY(135deg)
    translateZ(calc(var(--cube-size) / 2));
}
.cube .s-3 {
  transform: rotateX(-35deg) rotateY(225deg)
    translateZ(calc(var(--cube-size) / 2));
}
.cube .s-4 {
  transform: rotateX(-35deg) rotateY(315deg)
    translateZ(calc(var(--cube-size) / 2));
  background-color: var(--cube-3);
  animation: 3s cubeLeftSideBgChange infinite ease-in-out forwards;
}
.cube .s-5 {
  transform: rotateX(55deg) rotateZ(-45deg)
    translateZ(calc(var(--cube-size) / 2));
  background-color: var(--cube-1);
  animation: 3s cubeTopSideBgChange infinite ease-in-out forwards;
}
.cube .s-6 {
  transform: rotateX(55deg) rotateY(180deg) rotateZ(-45deg)
    translateZ(calc(var(--cube-size) / 2));
}

@media (max-width: 1400px) {
  .page_description_container {
    flex-direction: column;
  }

  .page_description_title p {
    text-align: left;
  }

  .page_description_form {
    border-left: none;
    flex: 4;
  }
}


@media (max-width: 1199px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .form_field {
    display: block;
  }

  .form_field input {
    padding: 0;
  }

  .form_field select {
    width: 100%;
  }
}


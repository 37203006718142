.wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  flex: 1;
}

.container {
  min-width: var(--base-min-width);
}

.form_sent {
  padding: 10vh 5vh;
  text-align: center;
}

@media (max-width: 767px) {
  .form_sent {
    min-height: calc(90vh - var(--header-height) * 3);
    margin-top: 10vh;
  }
}

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-red: #f00;
  --color-red-hover: #db0000;
  --color-gray: #d1d3d4;
  --color-gray-dark: #808285;
  --color-gray-side: #f1f1f1;
  --color-cotout: #f8f8f8;
  --color-blue: #003a5c;
  --color-blue-dark: #001d38;
  --color-magenta: #581949;
  --color-placeholder: var(--color-sub);
  --cube-size: 7rem;
  --cube-1: #ff333a;
  --cube-2: #d4030a;
  --cube-3: #5c0004;
  --g-start: var(--color-magenta);
  --g-end: #00223e;
  --gradient-main: linear-gradient(30deg, var(--g-start) -9%, var(--g-end) 50%);
  --gradient-main-reverse: linear-gradient(-300deg, var(--g-start) -5%, var(--g-end) 95%);
  --font-sans-serif: "Inconsolata", sans-serif;
  --font-sans-serif-header: "Inconsolata", sans-serif;
  --font-serif: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --base-background-color: var(--color-white);
  --base-font-family: var(--font-sans-serif);
  --base-font-size: 2.4rem;
  --base-line-height: 2.083333333;
  --base-text-color: var(--color-gray-dark);
  --base-min-width: 320px;
  --base-transition: 0.3s;
  --gap: 6rem;
  --base-link-color: inherit;
  --btn-background: var(--color-red);
  --btn-background-hover: var(--color-red-hover);
  --btn-color: var(--color-white);
  --btn-height: 2em;
  --btn-radius: 0em;
  --btn-padding: 0.5715125em 1.275em;
  --btn-font-size: 2.4rem;
  --btn-font-family: var(--font-sans-serif);
  --form-font-family: "Inconsolata SemiCondensed", sans-serif;
  --form-font-size: 4.6rem;
  --form-element-padding: 0 0.75em;
  --form-element-border-radius: 0;
  --form-element-border-color: var(--color-gray);
  --form-element-focus-border-color: var(--color-red);
  --placeholder-color: var(--color-gray);
  --headings-font-family: var(--font-sans-serif-header);
  --headings-sizes-h1: 3.6rem;
  --headings-sizes-h2: 4.8rem;
  --headings-sizes-h3: 2.4rem;
  --headings-sizes-h4: 2.4rem;
  --headings-sizes-h5: 3.4rem;
  --headings-sizes-h6: 1.8rem;
  --h-space: 4rem;
  --base-shape-size: 1.4rem;
  --l-side-width: 12rem;
  --r-side-width: 4.8rem;
  --header-height: 7.2rem;
  --content-padding: 3.2rem;
}


@media (max-width: 1920px) {
  :root {
    --base-font-size: 2.4rem;
    --cube-size: 7rem;
    --form-font-size: 2.4rem;
    --gap: 4rem;
  }
}
@media (max-width: 1199px) {
  :root {
    --headings-sizes-h5: 2.4rem;
    --form-font-size: 2.4rem;
    --l-side-width: 0px;
    --r-side-width: 0px;
    --gap: 3rem;
  }
}
@media (max-width: 1600px) {
  :root {
    --base-font-size: 1.6rem;
    --header-height: 6rem;
    --cube-size: 6rem;
  }
}
@media (max-width: 991px) {
  :root {
    --headings-sizes-h1: 2.6rem;
    --headings-sizes-h2: 3.2rem;
    --headings-sizes-h3: 2.2rem;
    --headings-sizes-h4: 1.8rem;
    --headings-sizes-h5: 1.8rem;
    --headings-sizes-h6: 1.6rem;
    --form-font-size: 1.8rem;
    --gap: 2.4rem;
    --h-space: 1.6rem;
  }
}
@media (max-width: 767px) {
  :root {
    --headings-sizes-h1: 1.8rem;
    --headings-sizes-h2: 3rem;
    --headings-sizes-h3: 2rem;
    --headings-sizes-h4: 1.8rem;
    --headings-sizes-h5: 1.6rem;
    --headings-sizes-h6: 1.4rem;
    --form-font-size: 1.8rem;
    --gap: 2rem;
  }
}
@media (max-width: 599px) {
  :root {
    --content-padding: 1.1rem;
  }
}
